// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nasi-klienci-js": () => import("./../../../src/pages/nasi-klienci.js" /* webpackChunkName: "component---src-pages-nasi-klienci-js" */),
  "component---src-pages-nasze-projekty-js": () => import("./../../../src/pages/nasze-projekty.js" /* webpackChunkName: "component---src-pages-nasze-projekty-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-uslugi-js": () => import("./../../../src/pages/uslugi.js" /* webpackChunkName: "component---src-pages-uslugi-js" */),
  "component---src-pages-wyslano-js": () => import("./../../../src/pages/wyslano.js" /* webpackChunkName: "component---src-pages-wyslano-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */)
}

